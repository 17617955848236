<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modal-form-crear-plantilla"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Plantilla vehiculos</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>Turno</th>
                      <th>Vehiculo</th>
                      <th>Empresa</th>
                      <th>Operacion</th>
                      <th>Tipo Vehiculo</th>
                      <th>Bloque</th>
                      <th>Producto</th>
                      <th>Tipo Tarifa</th>
                      <th>Archivo</th>
                    </tr>
                  </thead>
                  <tbody v-for="(turns) in turnosLiqui" :key="turns.id">
                    <tr>
                      <td
                        class="text-center"
                        style="font-size: 11px; width: 7%"
                      >
                        {{ turns.id }}
                      </td>
                      <td
                        class="text-center"
                        style="font-size: 11px; width: 7%"
                      >
                        {{ turns.vehiculo.placa }}
                      </td>
                      <td>
                        {{ turns.empresa.razon_social }}
                      </td>
                      <td>
                        {{ turns.operacion.Noperacion }}
                      </td>
                      <td>
                        {{ turns.tipo_vehiculo.nombre }}
                      </td>
                      <td>
                        {{ turns.bloque.nombre }}
                      </td>

                      <td>
                        {{ turns.producto.nombre }}
                      </td>
                      <td>
                        {{ turns.tipoTarifa }}
                      </td>
                      <td>
                        <div v-if="fileDoc && flag === turns.id">
                          <button
                            type="button"
                            class="btn btn-sm bg-warning"
                            @click="deletDoc()"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>

                          <button
                            type="button"
                            class="btn btn-sm bg-success"
                            @click="subirDoc(turns)"
                          >
                            <i class="fas fa-upload"></i>
                          </button>
                        </div>
                        <div v-else>
                          <div v-if="turns.link_soporte == null">
                            <input
                              type="file"
                              class="form-control-file"
                              ref="file"
                              id="btnFile"
                              @change="getFile(turns.id, $event)"
                              accept="application/msword,application/pdf,application/rtf,image/*"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          v-if="
                            turns.link_soporte !== null &&
                            turns.link_soporte !== ''
                          "
                          class="btn btn-success btn-sm"
                          @click="downloadPlanilla(turns.link_soporte)"
                        >
                          <i class="fas fa-download"></i>
                        </button>
                        <button
                          type="button"
                          v-if="
                            turns.link_soporte !== null &&
                            turns.link_soporte !== ''
                          "
                          class="btn btn-danger btn-sm"
                          @click="deletBd(turns)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "TifLiquidacionPlantilla",
  components: {},
  data() {
    return {
      flag: null,
      fileDoc: null,
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      liqui: null,
      turnosLiqui: {},
    };
  },
  validations: {},

  methods: {
    llenarModal(liqui) {
      this.liqui = liqui;
      let datos = {
        id: liqui.id,
        bloque_id: liqui.bloque_id,
        tif_operacion_id: liqui.tif_operacion_id,
        producto_id: liqui.producto_id,
        tipo_tarifa: liqui.tipo_tarifa,
        fecha_ini: liqui.fecha_ini,
        fecha_fin: liqui.fecha_fin,
      };

      axios({
        method: "get",
        url: "/api/tif/liquidaciones/turnosLiqui",
        params: datos,
      })
        .then((response) => {
          this.turnosLiqui = response.data;
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    downloadPlanilla(planilla) {
      window.open(this.uri_docs + planilla, "_blank");
    },

    deletBd(liqui) {
      this.$parent.cargando = true;
      axios
        .delete("/api/tep/liquidacionesInternos/destroyPlanilla", {
          params: {
            id: liqui.planilla.id,
          },
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "la planilla se eliminó correctamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.llenarModal(this.liqui);
          this.fileDoc = null;
          this.$parent.cargando = false;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getFile(id, e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        if (this.validaArchivo(file)) {
          this.fileDoc = file;
          this.flag = id;
        }
      }
    },

    validaArchivo(file) {
      let flag = true;
      if (file.size > 2048000) {
        flag = false;
        let inputImage = document.getElementById("btnFile");
        inputImage.value = "";
        this.$swal({
          icon: "error",
          title: `El Archivo es muy pesado para subirlo`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
      return flag;
    },

    deletDoc() {
      this.fileDoc = null;
    },

    subirDoc(turno) {
      this.$parent.cargando = true;
      let formData = new FormData();
      formData.append("tif_turno_id", turno.id);
      formData.append("link_soporte", this.fileDoc);
      axios
        .post("api/tif/liquidaciones/subirPlanilla", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$swal({
            icon: "success",
            title: "Los datos se guardaron exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.llenar_modal(this.liqui);
          this.fileDoc = null;
          this.flag = null;
          this.$parent.cargando = false;
        })
        .catch(function (error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },
};
</script>
